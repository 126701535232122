import {EventEmitter, Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {AuthApi} from './apis/auth.api';
import {Storage} from '@ionic/storage';
import {HttpClient} from '@angular/common/http';
import {UserApi} from './apis/user.api';
import {QRCodeApi} from './apis/qrcode.api';
import {ProProductsApi} from './apis/proProducts.api';
import {CartApi} from './apis/cart.api';
import {VersionNewsApi} from './apis/versionNews.api';
import {LotApi} from './apis/lot.api';
import {ExpertiseApi} from './apis/expertise.api';
import {RegistersApi} from './apis/registers.api';
import {InsuranceApi} from './apis/insurance.api';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _authApi: AuthApi;
  private _userApi: UserApi;
  private _qrcodeApi: QRCodeApi;
  private _proProductApi: ProProductsApi;
  private _cartApi: CartApi;
  private _lotApi: LotApi;
  private _versionNews: VersionNewsApi;
  private _expertiseApi: ExpertiseApi;
  private _registerApi: RegistersApi;
  private _insuranceApi: InsuranceApi;
  refreshUserData: EventEmitter<any> = new EventEmitter();

  constructor(private storage: Storage, private platform: Platform, private http: HttpClient) {
    this._authApi = new AuthApi(storage, http);
    this._userApi = new UserApi(this._authApi, http);
    this._qrcodeApi = new QRCodeApi(this._authApi, http);
    this._proProductApi = new ProProductsApi(this._authApi, http);
    this._cartApi = new CartApi(this._authApi, http);
    this._versionNews = new VersionNewsApi(this._authApi, http);
    this._lotApi = new LotApi(this._authApi, http);
    this._expertiseApi = new ExpertiseApi(this._authApi, http);
    this._registerApi = new RegistersApi(this._authApi, http);
    this._insuranceApi = new InsuranceApi(this._authApi, http);
  }

  get userApi(): UserApi {
    return this._userApi;
  }

  get registerApi(): RegistersApi {
    return this._registerApi;
  }

  get qrcodeApi(): QRCodeApi {
    return this._qrcodeApi;
  }

  get authApi(): AuthApi {
    return this._authApi;
  }

  get proProductApi(): ProProductsApi {
    return this._proProductApi;
  }

  get cartApi(): CartApi {
    return this._cartApi;
  }

  get lotApi(): LotApi {
    return this._lotApi;
  }

  get cartVersionNews(): VersionNewsApi {
    return this._versionNews;
  }

  get expertiseApi(): ExpertiseApi {
    return this._expertiseApi;
  }

  get insuranceApi(): InsuranceApi {
    return this._insuranceApi;
  }
}
