import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from '@ionic/storage';
import {ApiService} from '../api.service';
import {AuthApi} from './auth.api';

export class CartApi {
  constructor(private authApi: AuthApi, private http: HttpClient) {

  }

  add(json, distrib = null){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    if (distrib){
      return this.http
          .post(`${environment.apiUrl}/pro/cart?distributor=${distrib}`, json, { headers })
          .toPromise();
    }

    return this.http
        .post(`${environment.apiUrl}/pro/cart`, json, { headers })
        .toPromise();
  }

  get(distributorId = null){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    if (distributorId){
      return this.http
          .get(`${environment.apiUrl}/pro/cart?distributor=${distributorId}`, { headers })
          .toPromise();
    }
    return this.http
        .get(`${environment.apiUrl}/pro/cart`, { headers })
        .toPromise();
  }

  getDeclination(distributorId = null){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .get(`${environment.apiUrl}/pro/declination`, { headers })
        .toPromise();
  }

  delete(id){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .delete(`${environment.apiUrl}/pro/cart/delete/${id}`, { headers })
        .toPromise();
  }

  updateQuantity(data){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .patch(`${environment.apiUrl}/pro/cart`, data, { headers })
        .toPromise();
  }
}
