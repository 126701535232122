import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from '@ionic/storage';
import {ApiService} from '../api.service';
import {AuthApi} from './auth.api';

export class QRCodeApi {
  constructor(private authApi: AuthApi, private http: HttpClient) {

  }

  get(qrcode, fromExpertise = false){
    if (fromExpertise === true) {
      return this.http
          .get(`${environment.apiUrl}/public/expertise/qrcode/${qrcode}`)
          .toPromise();
    } else {
      return this.http
          .get(`${environment.apiUrl}/public/${qrcode}`)
          .toPromise();
    }
  }


  searchMarque(search){
    if (!search){
      search = '';
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .get(`${environment.apiUrl}/brand?search=${search}`, { headers })
        .toPromise();
  }

  searchType(search){
    if (!search){
      search = '';
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .get(`${environment.apiUrl}/type?search=${search}`, { headers })
        .toPromise();
  }

  searchModele(search, marque){
    if (!search){
      search = '';
    }

    if (!marque){
      marque = '';
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .get(`${environment.apiUrl}/model?search=${search}&brand=${marque}`, { headers })
        .toPromise();
  }


  getPrivate(qrcode){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .get(`${environment.apiUrl}/products/${qrcode}`, { headers })
        .toPromise();
  }

  getWithToken(token){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .get(`${environment.apiUrl}/public/token/${token}`, { headers })
        .toPromise();
  }

  setRenunciation(qrcodeData){
    return this.http
        .post(`${environment.apiUrl}/renunciating`, qrcodeData)
        .toPromise();
  }


  post(qrcodeData){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .post(`${environment.apiUrl}/products`, qrcodeData, { headers })
        .toPromise();
  }



  recover(data){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .post(`${environment.apiUrl}/recover`, data, { headers })
        .toPromise();
  }

  fnuciRecover(data){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .post(`${environment.apiUrl}/fnuciRecover`, data, { headers })
        .toPromise();
  }

  fnuciTransfer(data){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .post(`${environment.apiUrl}/fnuciTransfer`, data, { headers })
        .toPromise();
  }

  fnuciTransferFromUser(data){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
        .post(`${environment.apiUrl}/fnuciTransferFromUser`, data, { headers })
        .toPromise();
  }

  getFnuciTransferFromUser(code){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
        .get(`${environment.apiUrl}/fnuciTransferFromUser/${code}`, { headers })
        .toPromise();
  }

  professionalBuy(data){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .post(`${environment.apiUrl}/professionalbuy`, data, { headers })
        .toPromise();
  }

  transfer(data){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .post(`${environment.apiUrl}/transfer`, data, { headers })
        .toPromise();
  }

  restitution(data){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .post(`${environment.apiUrl}/restitution`, data, { headers })
        .toPromise();
  }

  deleteMediaData(data){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .post(`${environment.apiUrl}/reset`, data, { headers })
        .toPromise();
  }

  downloadCertificat(qrcode, complete){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http.get(`${environment.apiUrl}/document/certificat/` + qrcode, { responseType: 'blob', headers }).subscribe(x => {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([x], { type: 'pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      const link = document.createElement('a');
      link.href = data;
      link.download = 'certificat.pdf';
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    },
        error => {
            return null;
        },
        complete
        );
  }

  downloadAttestation(qrcode, complete){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http.get(`${environment.apiUrl}/document/attestation/` + qrcode, { responseType: 'blob', headers }).subscribe(x => {
          // It is necessary to create a new blob object with mime-type explicitly set
          // otherwise only Chrome works like it should
          const newBlob = new Blob([x], { type: 'pdf' });

          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);

          const link = document.createElement('a');
          link.href = data;
          link.download = 'attestation.pdf';
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        },
        error => {
          return null;
        },
        complete
    );
  }
}
