import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import {Observable} from 'rxjs';
import {Storage} from '@ionic/storage';
import {ApiService} from '../api.service';
import {NavController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class CheckGuardService implements CanActivate {

    constructor(private router: Router,
                private apiService: ApiService,
                private storage: Storage,
                public route: ActivatedRoute,
                private navController: NavController) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve, reject) => {
            this.apiService.authApi.getToken().then((res) => {
                if (res){
                    this.navController.navigateRoot('/home');
                    resolve(false);
                }
                else{
                    resolve(true);
                }
            }).catch((res) => {
                resolve(true);
            });
        });
    }
}
