import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from '@ionic/storage';
import {ApiService} from '../api.service';
import {AuthApi} from './auth.api';

export class UserApi {
  constructor(private authApi: AuthApi, private http: HttpClient) {

  }

  getMe(){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .get(`${environment.apiUrl}/me`, { headers })
        .toPromise();
  }

  get(id){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .get(`${environment.apiUrl}/user/${id}`, { headers })
        .toPromise();
  }

  patchMe(data: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });


    return this.http
        .patch(`${environment.apiUrl}/me`, data, { headers })
        .toPromise();
  }

  updateAddress(data: any, distributorId = null){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    if (distributorId){
      return this.http
          .post(`${environment.apiUrl}/update-address?distributor=${distributorId}`, data, { headers })
          .toPromise();
    }

    return this.http
        .post(`${environment.apiUrl}/update-address`, data, { headers })
        .toPromise();
  }

  command(data: any, distributorId = null){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    if (distributorId){
      return this.http
          .post(`${environment.apiUrl}/command?distributor=${distributorId}`, data, { headers })
          .toPromise();
    }

    return this.http
        .post(`${environment.apiUrl}/command`, data, { headers })
        .toPromise();
  }

  distributors(search){
    if (!search){
      search = '';
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .get(`${environment.apiUrl}/distributors?search=${search}`, { headers })
        .toPromise();
  }

  postSeeVersionNews(){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .post(`${environment.apiUrl}/see-version-news`, true, { headers })
        .toPromise();
  }
}
