import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthApi} from './auth.api';

export class InsuranceApi {
  constructor(private authApi: AuthApi, private http: HttpClient) {

  }

  acceptInsuranceTransfer(data){
    return this.http
        .post(`${environment.apiUrl}/insurance/transfer`, {data})
        .toPromise();
  }
}
