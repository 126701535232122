import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import {Observable} from 'rxjs';
import {Storage} from '@ionic/storage';
import {ApiService} from '../api.service';
import {NavController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(private router: Router,
                private apiService: ApiService,
                private storage: Storage,
                public route: ActivatedRoute,
                private navController: NavController) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve, reject) => {
            this.apiService.authApi.getToken().then((res) => {
                if (res){
                    this.apiService.authApi.currentToken = res;

                    this.apiService.userApi.getMe().then((e: any) => {
                        this.apiService.authApi.currentUser = e;

                        // tslint:disable-next-line:triple-equals
                        if (e.status == '0'){
                            // Si on est un utilisateur on redirige
                            this.apiService.authApi.setToken(null);
                            this.navController.navigateRoot('/');
                            resolve(false);
                        }
                        else{
                            resolve(true);
                        }

                    }).catch(() => {
                        this.apiService.authApi.setToken(null);
                        this.navController.navigateRoot('/auth/login');
                        resolve(false);
                    });
                }
                else{
                    this.apiService.authApi.setToken(null);
                    this.navController.navigateRoot('/auth/login');
                    resolve(false);
                }
            }).catch((res) => {
                this.apiService.authApi.setToken(null);
                this.navController.navigateRoot('/auth/login');
                resolve(false);
            });
        });
    }
}
