import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from '@ionic/storage';
import {ApiService} from '../api.service';
import {AuthApi} from './auth.api';

export class RegistersApi {
  constructor(private authApi: AuthApi, private http: HttpClient) {

  }

  getAll(length = 5, offset = 0, search = null) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    let params = '?length=' + length + '&offset=' + offset;
    if (search) {
      params = params + '&search=' + search;
    }

    return this.http
        .get(`${environment.apiUrl}/register${params}`, { headers })
        .toPromise();
  }

    count(search = null) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.authApi.currentToken
        });

        let params = '';

        if (search) {
            params = '?search=' + search;
        }

        return this.http
            .get(`${environment.apiUrl}/register/count${params}`, { headers })
            .toPromise();
    }

    get(fnuciCode) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.authApi.currentToken
        });

        return this.http
            .get(`${environment.apiUrl}/register/${fnuciCode}`, { headers })
            .toPromise();
    }

  postRegisterBook(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .post(`${environment.apiUrl}/register`, data, { headers })
        .toPromise();
  }

  patchRegisterBook(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .patch(`${environment.apiUrl}/register`, data, { headers })
        .toPromise();
  }

  downloadIdentity(id, complete){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http.get(`${environment.apiUrl}/register/download/` + id,
        { responseType: 'blob', headers }).subscribe(x => {
          // It is necessary to create a new blob object with mime-type explicitly set
          // otherwise only Chrome works like it should
        let type = 'pdf';
        switch (x.type) {
          case 'application/pdf':
            type = 'pdf';
            break;
          case 'image/jpg':
            type = 'jpg';
            break;
          case 'image/jpeg':
            type = 'jpeg';
            break;
          case 'image/png':
            type = 'png';
            break;
        }
        const newBlob = new Blob([x], { type });

          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = data;
        link.download = 'identité.' + type;
          // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        },
        error => {
          return null;
        },
        complete
    );
  }

    export(complete){
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.authApi.currentToken
        });

        return this.http.get(`${environment.apiUrl}/register/export`,
            { responseType: 'blob', headers }).subscribe(x => {
                // It is necessary to create a new blob object with mime-type explicitly set
                // otherwise only Chrome works like it should
                const newBlob = new Blob([x], { type: 'xls' });

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                }

                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const data = window.URL.createObjectURL(newBlob);

                const link = document.createElement('a');
                link.href = data;
                link.download = 'registreExport.xls';
                // this is necessary as link.click() does not work on the latest firefox
                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
            },
            error => {
                return null;
            },
            complete
        );
    }
}
