import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from './services/guard/auth-guard.service';
import {CheckGuardService} from './services/guard/check-guard.service';
import {UserGuardService} from './services/guard/user-guard.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule),
    canActivate: [CheckGuardService]
  },
  {
    path: 'insurance',
    loadChildren: () => import('./insurance/accept-insurance-transfer/accept-insurance-transfer.module').then( m => m.AcceptInsuranceTransferPageModule)
  },
  {
    path: ':qrcode',
    loadChildren: () => import('./qrcode/qrcode.module').then( m => m.QrcodePageModule)
  },
  {
    path: 'home/renunciation/answer/:token',
    loadChildren: () => import('./home/renonciation/answer/answer.module').then( m => m.AnswerPageModule)
  },
  {
    path: 'home/lot/ask',
    loadChildren: () => import('./home/lot/pre-activation/pre-activation.module').then( m => m.PreActivationPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'home/lot/activation/:id',
    loadChildren: () => import('./home/lot/activation/activation.module').then( m => m.ActivationPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: '',
    loadChildren: () => import('./default/default.module').then( m => m.DefaultPageModule),
    canActivate: [CheckGuardService]
  },
  {
    path: 'expertise/qrcode/:qrcode/code',
    loadChildren: () => import('./expertise/code/code.module').then( m => m.CodePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'expertise/qrcode',
    loadChildren: () => import('./expertise/qrcode/qrcode.module').then( m => m.QrcodePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'expertise/:qrcode/:code/edit/general',
    loadChildren: () => import('./expertise/home-edit/home-edit.module').then( m => m.HomeEditPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'expertise/:qrcode/:code/edit/categories',
    loadChildren: () => import('./expertise/detail-edit/detail-edit.module').then( m => m.DetailEditPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'public/expertise/:code/:link',
    loadChildren: () => import('./expertise/public-expertise/public-expertise.module').then( m => m.PublicExpertisePageModule),
  },
  {
    path: 'expertise/:qrcode/:code/create/owner',
    loadChildren: () => import('./expertise/owner-add/owner-add.module').then( m => m.OwnerAddPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'expertise/:qrcode/:code/create/product',
    loadChildren: () => import('./expertise/product-add/product-add.module').then( m => m.ProductAddPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'fnuci/change-operator',
    loadChildren: () => import('./home/change-operator/change-operator.module').then( m => m.ChangeOperatorPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
