import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from '@ionic/storage';

export class AuthApi {

    currentToken: any;
    currentUser: any;


  constructor(private storage: Storage, private http: HttpClient) {

  }


  getToken(){
      return this.storage.get('token');
  }

  setToken(value = null){
      if (value == null){
          return this.storage.remove('token');
      }
      else {
          return this.storage.set('token', value);
      }
  }

  postRegisterMail(registerData){
       return this.http
            .post(`${environment.apiUrl}/register/mail`, registerData)
            .toPromise();
  }

  postRegisterCode(registerData){
        return this.http
            .post(`${environment.apiUrl}/register/code`, registerData)
            .toPromise();
  }

  postRegister(registerData){
        return this.http
            .post(`${environment.apiUrl}/register`, registerData)
            .toPromise();
  }


  postLogin(email: string, password: string){
      const params = {
          email,
          password
      };

      return this.http
          .post(`${environment.apiUrl}/login`, params)
          .toPromise();
  }

  postAutoConnect(params){
      return this.http
          .post(`${environment.apiUrl}/auth/auto-connect`, params)
          .toPromise();
  }

  postForgotPassword(email, customResetPath = window.location.origin + '/auth/resetpassword/%TOKEN%') {
      const params = {
          email,
          reset_path: customResetPath
      };

      return this.http
          .post(`${environment.apiUrl}/password/forgot`, params)
          .toPromise();
  }

  postResetPasswordPassword(password: string, token: string) {
      const params = {
          token,
          password,
          password_confirmed: password
      };

      return this.http
          .post(`${environment.apiUrl}/password/reset`, params )
          .toPromise();
  }


}
