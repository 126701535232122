import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthApi} from './auth.api';

export class VersionNewsApi {
  constructor(private authApi: AuthApi, private http: HttpClient) {

  }

  get(){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .get(`${environment.apiUrl}/version-news`, { headers })
        .toPromise();
  }
}
