import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {AuthApi} from './auth.api';

export class ExpertiseApi {
  constructor(private authApi: AuthApi, private http: HttpClient) {

  }

  getExpertise(qrcode){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .get(`${environment.apiUrl}/expertise/${qrcode}`, { headers })
        .toPromise();
  }

  getPublicExpertise(code, link){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
        .get(`${environment.apiUrl}/public/expertise/${code}/${link}`, { headers })
        .toPromise();
  }

  getCategoriesProgression(code, link){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });


    return this.http
        .get(`${environment.apiUrl}/public/expertise/categories/${code}/${link}`, { headers })
        .toPromise();
  }

    getCategoriesProgressionPublicDetail(qrcode, expertiseCode){
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.authApi.currentToken
        });

        return this.http
            .get(`${environment.apiUrl}/public/expertise/categories/${qrcode}/${expertiseCode}/detail`, { headers })
            .toPromise();
    }

  getCategoriesProgressionDetail(qrcode, expertiseCode){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .get(`${environment.apiUrl}/expertise/categories/${qrcode}/${expertiseCode}/detail`, { headers })
        .toPromise();
  }

  createExpertise(qrcode, expertiseCode){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .post(`${environment.apiUrl}/expertise/create/${qrcode}/${expertiseCode}`, [],  { headers })
        .toPromise();
  }

    postOwner(data){
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.authApi.currentToken
        });

        return this.http
            .post(`${environment.apiUrl}/expertise/owner/create`, data,  { headers })
            .toPromise();
    }

    getOwner(qrcode){
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.authApi.currentToken
        });

        return this.http
            .get(`${environment.apiUrl}/expertise/owner/get/${qrcode}`,  { headers })
            .toPromise();
    }

  saveExpertise(qrcode, expertiseCode, data){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .patch(`${environment.apiUrl}/expertise/save/${qrcode}/${expertiseCode}`, data,  { headers })
        .toPromise();
  }

  validateExpertise(qrcode, expertiseCode, data){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .patch(`${environment.apiUrl}/expertise/validate/${qrcode}/${expertiseCode}`, data,  { headers })
        .toPromise();
  }

  finishExpertise(qrcode, expertiseCode, data){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .patch(`${environment.apiUrl}/expertise/finish/${qrcode}/${expertiseCode}`, data,  { headers })
        .toPromise();
  }

  createAgainstExpertise(qrcode, expertiseCode){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    return this.http
        .post(`${environment.apiUrl}/againstExpertise/create/${qrcode}/${expertiseCode}`, [],  { headers })
        .toPromise();
  }

  downloadPdf(expertiseCode, complete){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(`${environment.apiUrl}/document/expertise/` + expertiseCode,
        { responseType: 'blob' }).subscribe(x => {
          // It is necessary to create a new blob object with mime-type explicitly set
          // otherwise only Chrome works like it should
          const newBlob = new Blob([x], { type: 'pdf' });

          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);

          const link = document.createElement('a');
          link.href = data;
          link.download = 'certificat.pdf';
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        },
        error => {
          return null;
        },
        complete
    );
  }

  downloadPaperVersion(complete){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(`${environment.apiUrl}/document/expertisePaperVersion`,
        { responseType: 'blob' }).subscribe(x => {
          // It is necessary to create a new blob object with mime-type explicitly set
          // otherwise only Chrome works like it should
          const newBlob = new Blob([x], { type: 'pdf' });

          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);

          const link = document.createElement('a');
          link.href = data;
          link.download = 'blank_expertise.pdf';
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        },
        error => {
          return null;
        },
        complete
    );
  }

    downloadCession(complete){
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        return this.http.get(`${environment.apiUrl}/document/cessionDocument`,
            { responseType: 'blob' }).subscribe(x => {
                // It is necessary to create a new blob object with mime-type explicitly set
                // otherwise only Chrome works like it should
                const newBlob = new Blob([x], { type: 'pdf' });

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                }

                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const data = window.URL.createObjectURL(newBlob);

                const link = document.createElement('a');
                link.href = data;
                link.download = 'Certificat de cession.pdf';
                // this is necessary as link.click() does not work on the latest firefox
                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
            },
            error => {
                return null;
            },
            complete
        );
    }
}
