import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Storage} from '@ionic/storage';
import {ApiService} from '../api.service';
import {AuthApi} from './auth.api';

export class ProProductsApi {
  constructor(private authApi: AuthApi, private http: HttpClient) {

  }

  getAll(distrib = null){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authApi.currentToken
    });

    if (distrib){
      return this.http
          .get(`${environment.apiUrl}/pro/products?distributor=${distrib}`, { headers })
          .toPromise();
    }

    return this.http
        .get(`${environment.apiUrl}/pro/products`, { headers })
        .toPromise();
  }
}
